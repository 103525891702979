import style from './LoginPage.module.scss';

import React, { useEffect, useState } from 'react';

import config from '@/utility/config';
import { getDefaultStaticProps } from '@/utility/getDefaultProps';

import { useAuthContext } from '@/contexts/AuthContext';
import { CommunityPortalContextProvider } from '@/contexts/CommunityPortalContext';

import NextImage from '@/components/common/NextImage';
import PortalPagesMetaTags from '@/components/common/PortalPagesMetaTags';

import LoginForm from '@/pages/login/components/LoginForm';
import { NoCommunityModal } from '@/pages/login/components/NoCommunityModal/NoCommunityModal';

import { NO_COMMUNITY_ERROR, sideImageData } from './constants';

const LoginPage = () => {
  const { loginError, socialLoginError, userHaveNoCommunities, logout } =
    useAuthContext();

  const className = 'c-LoginPage';
  const [showNoCommunityModal, setShowNoCommunityModal] = useState(false);

  useEffect(() => {
    if (
      loginError === NO_COMMUNITY_ERROR ||
      socialLoginError === NO_COMMUNITY_ERROR ||
      userHaveNoCommunities
    ) {
      setShowNoCommunityModal(true);
    }
  }, [loginError, socialLoginError, userHaveNoCommunities]);

  return (
    <>
      <PortalPagesMetaTags />
      <div className="flex h-screen items-center bg-light-f6">
        <div className={style[className]}>
          <div className={style[`${className}__container`]}>
            <div className={style[`${className}__media`]}>
              <NextImage {...sideImageData} />
            </div>
            <div className="m-auto flex-1 px-24">
              <LoginForm />
            </div>
          </div>
        </div>
        {showNoCommunityModal && (
          <NoCommunityModal
            onClose={() => {
              setShowNoCommunityModal(false);
              // Redirect to homepage and logout
              logout(true, true, config.siteBaseUrl);
            }}
          />
        )}
      </div>
    </>
  );
};

const LoginPageWrapper = (props) => {
  return (
    <CommunityPortalContextProvider>
      <LoginPage {...props} />
    </CommunityPortalContextProvider>
  );
};

export default LoginPageWrapper;

export async function getStaticProps(ctx) {
  return getDefaultStaticProps(ctx);
}
