import { t } from '@/utility/localization';
import React from 'react';
import CloseIcon from '@/components/common/Icons/CloseIcon/CloseIcon';
import { usePathname } from 'next/navigation';
import {
  LOGIN_ROUTE,
  START_COMMUNITY_ONBOARDING_ROUTE
} from '@/utility/routesHelper';
import Link from 'next/link';
import NPLButton from '@/components/npl/NPLButton';
import { useAuthContext } from '@/contexts/AuthContext';

/**
 * Modal that is shown when the user is not a member of any community
 * @param {function} onClose - Callback function to close the modal
 * @returns {JSX.Element}
 * @constructor
 */
export const NoCommunityModal = ({ onClose }) => {
  const pathname = usePathname();

  const { logout } = useAuthContext();
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget && isLogInPage) {
      onClose();
    }
  };
  const isLogInPage = pathname === LOGIN_ROUTE;
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-md"
        onClick={handleBackdropClick}>
        <div className="relative max-w-[90%] rounded-28 bg-white-default shadow-npl-styles-shadow-02 md:max-w-500">
          <img
            src="/images/yellow-wave.png"
            alt="No community illustration"
            className="rounded-t-28"
          />
          {isLogInPage && (
            <button
              className="absolute right-10 top-10 flex h-40 w-40 items-center justify-center rounded-full bg-black bg-opacity-50"
              onClick={onClose}>
              <CloseIcon fill="#F5F5F5" />
            </button>
          )}

          <div className="px-24 py-32">
            <h3 className="text-heading-lg font-semibold text-npl-neutral-light-solid-12">
              {t('you-are-not-part-of-any-community-yet')}
            </h3>
            <p className="pt-8 text-body-md font-normal text-npl-neutral-light-solid-12 text-opacity-65">
              {t('join-or-start-your-own-community-today')}
            </p>
            <div className="mt-24 flex items-center justify-end gap-12">
              {!isLogInPage && (
                <NPLButton
                  hierarchy="outline"
                  size="lg"
                  buttonText={t('logout')}
                  onClick={logout}
                />
              )}
              <Link href={START_COMMUNITY_ONBOARDING_ROUTE}>
                <NPLButton
                  hierarchy="accent_primary"
                  buttonText={t('start-for-free')}
                  size="lg"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
